import { getScreenshot, getSweaterParts } from "./knittingpreview/scene";
import { Global } from "./static/global";
import { Settings } from "./static/settings";
import { Util } from "./static/util";

export async function methodData(
  url = "",
  data = {},
  method = "",
  retrieveMethod = (it: any) => it
) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: method, // *GET, POST, PUT, DELETE, etc.
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    ...(Object.keys(data).length === 0 ? {} : { body: JSON.stringify(data) }), // body data type must match "Content-Type" header
  });
  return retrieveMethod(response);
}

function countYarn() {
  const countYarns = getSweaterParts().map((it) => it.countYarn());
  const countYarnsMerged = Util.mergeDicts(countYarns);
  let totalYarns = 0;
  for (let key of Object.keys(countYarnsMerged)) {
    totalYarns += countYarnsMerged[key];
  }
  for (let key of Object.keys(countYarnsMerged)) {
    const fraction = countYarnsMerged[key] / totalYarns;
    countYarnsMerged[key] = Math.ceil(fraction * Settings.totalYarnSweater);
  }
  return countYarnsMerged;
}

function dataURLtoFile(dataurl: string, filename: string) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)![1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
async function uploadFile(file: File, key: string) {
  const formData = new FormData();

  formData.append("file", file);
  formData.append("mimetype", file.type);

  const url = `https://woolit.no/api/3d/${key}/image`;

  const response = await fetch(url, {
    method: "POST",
    credentials: "include",
    body: formData,
  });

  return response;
}

export function post(setSaving?: any, key?: string, redirect?: boolean) {
  const loadByKey = key && key !== "";

  setSaving && setSaving(true);
  //displayYarn();
  const url = "https://woolit.no/api/3d" + (loadByKey ? `/${key}` : "");
  const jsonData = {
    name: "sweater_bygd",
    yarnQuantities: countYarn(),
    symbols: JSON.parse(
      localStorage.getItem(Global.getPatternStorageName()) ?? "{}"
    ),
    design: JSON.parse(
      localStorage.getItem(Global.getSweaterStorageName()) ?? "{}"
    ),
    colors: JSON.parse(
      localStorage.getItem(Global.getColorsStorageName()) ?? "{}"
    ),
    size: localStorage.getItem(Global.getSizeIndexStorageName()),
    knittingMethod: localStorage.getItem(
      Global.getKnittingMethodIndexStorageName()
    ),
  };
  const base64 = getScreenshot();
  methodData(url, jsonData, loadByKey ? "PUT" : "POST", (it) => it.text()).then(
    (_key) => {
      _key = loadByKey ? key : _key;
      if (_key === undefined) {
        alert(`Error: undefined key`);
        return;
      }
      console.log(_key);
      const file = dataURLtoFile(base64, "image.png");
      uploadFile(file, _key).then((imgResponse) => {
        setSaving && setSaving(false);
        if (imgResponse.status !== 200) {
          alert(`Error: img response status !== 200`);
          return;
        }
        if (redirect) {
          // alert(`Sweater has successfully been uploaded to:\n${newUrl}`);
          window.location.assign(
            `https://shop.woolit.no/designer-bygd/${_key}`
          );
        } else {
          alert(`Sweater has been successfully saved`);

          // Jump to the key if you're not already on that link
          const pathName = window.location.pathname.substring(1);
          if (Util.isKey(pathName) || pathName === "") {
            if (key !== pathName) {
              window.location.assign(`https://bygd.woolit.no/${_key}`);
            }
          }
        }
      });
    }
  );
}
