import { RGBToRGBString, hexToRgb } from "../knittingpreview/colorutil";

//Global variables: const
export class Settings {
  static startMasksPer10Cm = 24;
  static sizes = ["XS", "S", "M", "L", "XL"];
  static lengthCM = [60, 62, 65, 67, 70]; // Kun for display
  static circumfencesCM = [93, 98, 107, 116, 124]; // Kun for display
  static circumfences = [168, 176, 192, 208, 224];
  static baseCircumfence = 192;
  static shirtTypes = ["raglan", "stitched_sleeves"];
  static maskHeight = Math.round(16 * (18 / 23));
  static maskWidth = 16;
  static maxPatternWidth = 36;
  static maxPatternHeight = 36;
  static verbose = true;
  static borderColorOutline = "#B4917B";
  static borderColorOutlineSnap = "#F1C40F";
  static borderColorOutlineRGBstring = RGBToRGBString(
    hexToRgb(this.borderColorOutline)
  );
  static borderColorOutlineSnapRGBstring = RGBToRGBString(
    hexToRgb(this.borderColorOutlineSnap)
  );
  static defaultPatternHeightVH = 20 * (18 / 23);

  static startingColors = [80, 6, 20, 83, 86];
  static colorsAllNames = [
    "mørk korallrød - 6111",
    "melert oker - 6533",
    "melert olivengrønn - 6590",
    "mørk lilla - 6138",
    "brun - 6141",
    "lys burgunderrød - 6139",
    "ubleket hvit - 6057",
    "mørk blågrå - 6104",
    "mørk blåfiolett - 6137",
    "burgunder - 6143",
    "melert klar eplegrønn - 6588",
    "grålig lavendelblå - 6130",
    "ren grønn - 6023",
    "marineblå - 6038",
    "lys varmgrå - 6106",
    "blågrønn - 6086",
    "mørk brun - 6010",
    "korallrosa - 6112",
    "mosegrønn - 6109",
    "melert mørk rosa - 6573",
    "lys gråblå - 6129",
    "bonderød - 6013",
    "limegrønn - 6113",
    "melert mørk brun - 6103",
    "melert lys lime - 6520",
    "melert terracottarød - 6596",
    "melert mintgrønn - 6587",
    "melert grålig lavendel - 6500",
    "cognac brun - 6140",
    "lys grønnturkis - 6127",
    "isgrønn - 6126",
    "rosa - 6121",
    "sjøgrønn - 6029",
    "mørk marineblå - 6105",
    "rød (kald) - 6071",
    "lillabrun - 6099",
    "mørk olivengrønn - 6110",
    "lys blågrå - 6050",
    "cognac - 6095",
    "melert lys grå - 6054",
    "oransjerød - 6131",
    "oker - 6133",
    "ecru - 6107",
    "lys lilla - 6124",
    "melert turkis - 6584",
    "olivengrønn - 6090",
    "gråfiolett - 6079",
    "blek rosa - 6108",
    "grønnlig blå - 6080",
    "mørk blålig grønn - 6085",
    "lys lavendelblå - 6125",
    "mørk grå - 6051",
    "melert lys rosa - 6574",
    "beige - 6142",
    "lys gul - 6119",
    "bondeblå - 6082",
    "blåturkis - 6031",
    "melert mørk terracotta - 6503",
    "melert lilla - 6577",
    "turkis - 6084",
    "støvet mørk rosa - 6073",
    "lys blå - 6081",
    "lys flyblå - 6128",
    "lillabeige - 6101",
    "lilla - 6077",
    "melert maisgul - 6502",
    "lys rosa - 6116",
    "mørk roserød - 6015",
    "jaktgrønn - 6089",
    "klar eplegrønn - 6088",
    "halvbleket hvit - 6047",
    "mørk ren grønn - 6024",
    "koksgrå - 6056",
    "melert grå - 6055",
    "melert lys brun - 6058",
    "melert mørk grå - 6061",
    "stålgrå - 6052",
    "melert brun - 6102",
    "lilla - 6134",
    "melert stålgrå - 6550",
    "mørk burgunder - 6072",
    "gammelrosa - 6122",
    "melert dyp rosa - 6568",
    "lys bondeblå - 6037",
    "svart - 6053",
    "gulbrun - 6093",
    "okergul - 6094",
    "terracottarød - 6096",
    "messinggul - 6092",
    "mørk rosa - 6018",
    "bleket hvit - 6046",
    "melert lys grønnturkis - 6527",
    "mørk cyklamen (kald) - 6135",
    "rødbrun - 6009",
    "mørk terracottarød - 6003",
    "melert lys blålilla - 6541",
    "melert blålilla - 6563",
    "melert oransje - 6570",
    "blek fersken - 6117",
    "oransje - 6132",
    "lys lime - 6120",
    "gul - 6118",
    "blålilla - 6063",
    "lys marineblå - 6036",
    "melert lys flyblå - 6528",
    "mørk flyblå - 6136",
    "klar blå - 6034",
  ];
  static colorsAll = [
    "#ac041c",
    "#995f3a",
    "#575a37",
    "#22112e",
    "#421f0e",
    "#370011",
    "#ece5d2",
    "#292f3c",
    "#1d1b27",
    "#620315",
    "#6e712d",
    "#6c6d8d",
    "#166639",
    "#101d36",
    "#b7afa5",
    "#2b7b7b",
    "#2a1913",
    "#ba1734",
    "#3e3c1f",
    "#702d3d",
    "#3e5a74",
    "#a11221",
    "#9e9040",
    "#21191a",
    "#a99f68",
    "#69200e",
    "#49716e",
    "#4f506a",
    "#591e06",
    "#789093",
    "#7c9b91",
    "#f2a2ad",
    "#084f5a",
    "#12131a",
    "#c40426",
    "#4d241d",
    "#1c1711",
    "#a9b8c4",
    "#b05624",
    "#bebcb8",
    "#bc1318",
    "#b55a27",
    "#c7a881",
    "#ad91ac",
    "#206a73",
    "#6a6e44",
    "#402d4a",
    "#cb7d67",
    "#2b5874",
    "#073536",
    "#7f80a4",
    "#373e45",
    "#8f5660",
    "#aa9286",
    "#e2cb92",
    "#0c3248",
    "#0493b5",
    "#5d0f0c",
    "#493655",
    "#36b3b8",
    "#c33f5e",
    "#618ba8",
    "#377285",
    "#775a52",
    "#674a76",
    "#986b2d",
    "#d8bbba",
    "#8b0220",
    "#202512",
    "#a8ac40",
    "#e8e9e4",
    "#0c2918",
    "#111111",
    "#979896",
    "#bba89a",
    "#444444",
    "#626f7a",
    "#725850",
    "#340d3d",
    "#757979",
    "#4a080e",
    "#ad6767",
    "#941246",
    "#2f5f7b",
    "#0a0a0a",
    "#6a370f",
    "#df8c2d",
    "#c6472e",
    "#8c5a19",
    "#a5143b",
    "#efeeef",
    "#78888b",
    "#550533",
    "#50130d",
    "#a11b1a",
    "#778096",
    "#2e3754",
    "#9d4921",
    "#dcb7a0",
    "#d44b2e",
    "#ddca76",
    "#dd972c",
    "#4f5d8f",
    "#032f57",
    "#335f6d",
    "#013343",
    "#1a4496",
  ]; //Must be lowercase
  static colorsAllID = [
    "316111",
    "316533",
    "316590",
    "316138",
    "316141",
    "316139",
    "316057",
    "316104",
    "316137",
    "316143",
    "316588",
    "316130",
    "316023",
    "316038",
    "316106",
    "316086",
    "316010",
    "316112",
    "316109",
    "316573",
    "316129",
    "316013",
    "316113",
    "316103",
    "316520",
    "316596",
    "316587",
    "316500",
    "316140",
    "316127",
    "316126",
    "316121",
    "316029",
    "316105",
    "316071",
    "316099",
    "316110",
    "316050",
    "316095",
    "316054",
    "316131",
    "316133",
    "316107",
    "316124",
    "316584",
    "316090",
    "316079",
    "316108",
    "316080",
    "316085",
    "316125",
    "316051",
    "316574",
    "316142",
    "316119",
    "316082",
    "316031",
    "316503",
    "316577",
    "316084",
    "316073",
    "316081",
    "316128",
    "316101",
    "316077",
    "316502",
    "316116",
    "316015",
    "316089",
    "316088",
    "316047",
    "316024",
    "316056",
    "316055",
    "316058",
    "316061",
    "316052",
    "316102",
    "316134",
    "316550",
    "316072",
    "316122",
    "316568",
    "316037",
    "316053",
    "316093",
    "316094",
    "316096",
    "316092",
    "316018",
    "316046",
    "316527",
    "316135",
    "316009",
    "316003",
    "316541",
    "316563",
    "316570",
    "316117",
    "316132",
    "316120",
    "316118",
    "316063",
    "316036",
    "316528",
    "316136",
    "316034",
  ];

  static version = "v6";
  static versionStorageName = "version_bygd";
  static sizeIndexStorageName = "size";
  static knittingMethodIndexStorageName = "knittingMethod";
  static sweaterStorageName = "sweater";
  static patternStorageName = "pattern_v5"; // _v5 Since I dont want the users to lose these patterns
  static colorsStorageName = "colors";

  static knitabilityWarningColor = "#f1c40f";
  static knitabilityWarningColorDisplay = "#FBAE3B"; //text display on white background
  static knitabilityErrorColor = "#ff0000";
  static emphasisColor = "#cc4e00";

  static limitMirror = true; //false is missing: delete - areagroup -> selected only
  static limitRepeat = true;

  static mirrorArmsDifferently = true;

  static totalYarnSweater = 5;
}
