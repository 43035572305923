import { useEffect, useRef, useState } from "react";
import {
  DrawTypes,
  KnitabilityProblem,
  RepeatMode,
  SweaterPartAreaGroup,
} from "../enums";
import {
  getSweaterParts,
  getSweaterPartsExceptCollar,
  runAfterLoadCanvasPush,
  runAfterLoadSweaterPush,
  setUpdateCanvasNextFrame,
} from "../knittingpreview/scene";
import { createCanvas } from "../knittingpreview/texturecanvas";
import { Pattern } from "../Pattern";
import { Settings } from "../static/settings";
import { SweaterPart } from "../SweaterPart";
import {
  loadGrid,
  onLoadImages,
  drawSelection,
  getGrid,
  drawGrid,
  drawBucket,
  clearPreview,
  hasLoadedImages,
  prevSetupDrawable,
} from "./gridcanvas";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ColorsPopup from "./ColorsPopup";
import PatternCanvas from "./PatternCanvas";
import Drawable from "../Drawable";
import React from "react";
import { Util } from "../static/util";
import GridButton from "../components/GridButton";
import NumberInput from "../components/NumberInput";
import GridCell from "./GridCell";
import { Tooltip } from "@mui/material";
import { Global } from "../static/global";
import { isKnitable } from "../KnitableChecker";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { selectknitability } from "../../store/knitabilityslice";
import { useSelector } from "react-redux";

function GridOverlay(props: any) {
  const overlayStyle: any = {
    display: "flex",
    margin: "10px",
    marginLeft: "15px",
    width: "calc(100% - 30px)",
    height: "calc(100% - 20px)",
    position: "absolute",
    gap: "5px",
    pointerEvents: "none",
  };

  const leftDown: any = {
    justifyContent: "flex-start",
    alignItems: "flex-end",
    ...overlayStyle,
  };
  const rightDown: any = {
    justifyContent: "flex-end",
    alignItems: "flex-end",
    ...overlayStyle,
  };
  const leftUp: any = {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    ...overlayStyle,
  };
  const rightUp: any = {
    justifyContent: "flex-end",
    alignItems: "flex-start",
    ...overlayStyle,
  };
  const middleUp: any = {
    justifyContent: "center",
    alignItems: "flex-start",
    ...overlayStyle,
  };

  const warningOverlay = useSelector(selectknitability).warningOverlay;
  return (
    <>
      <div style={{ ...middleUp }}>
        {props.isSweaterPart && (
          <div
            className="buttonContainer"
            style={{
              pointerEvents: "auto",
              marginRight: "8px",
            }}
          >
            <GridButton
              title="Mirror"
              onClick={() => {
                if (props.getDrawType() == DrawTypes.Pattern) {
                  props.setMirrorMemoPattern(1 - props.mirror);
                } else {
                  props.setMirrorMemoBrush(1 - props.mirror);
                }
                props.setMirror(1 - props.mirror);
              }}
              isSelected={props.mirror == 1}
              imageURL={"ui/mirror.png"}
              small={true}
              size={22}
            />
          </div>
        )}
      </div>
      <div style={{ ...leftDown }}>
        <p
          style={{
            fontSize: "20px",
            marginBottom: "0px",
            fontWeight: 300,
          }}
        >
          {props.drawable?.name ?? ""}
        </p>
      </div>
      <div style={{ ...leftUp }}>
        {!props.isSweaterPart && (
          <div className="buttonContainer" style={{ pointerEvents: "auto" }}>
            <GridButton
              title="Close"
              onClick={() => {
                if (
                  props.undoStack.length != 1 &&
                  !window.confirm(
                    "Are you sure you want to close without saving?"
                  )
                )
                  return;
                props.setSelectedEditPattern(undefined);
              }}
              imageURL={"ui/close.png"}
              small={true}
              size={22}
            />
          </div>
        )}

        <div className="buttonContainer" style={{ pointerEvents: "auto" }}>
          <GridButton
            title="Undo"
            onClick={() => {
              props.undoButtonClick();
            }}
            imageURL="ui/undo.png"
            small={true}
            size={35}
            opacity={props.undoButtonState() ? 1 : 0.25}
          />
          <GridButton
            title="Redo"
            onClick={() => {
              props.redoButtonClick();
            }}
            imageURL="ui/redo.png"
            small={true}
            size={35}
            opacity={props.redoButtonState() ? 1 : 0.25}
          />
        </div>
        {(props.isSweaterPart || props.selectedEditPattern.id != -1) && (
          <div className="buttonContainer" style={{ pointerEvents: "auto" }}>
            <div
              className="buttonContainerVertical"
              style={{ pointerEvents: "auto" }}
            >
              <GridButton
                title="Move stitches: Up"
                onClick={() => {
                  props.setDrawType(DrawTypes.Move, 1);
                }}
                imageURL="ui/up.png"
                small={true}
                size={15}
                isSelected={props.moveMode === 1}
                customStyle={{
                  height: "20px",
                }}
              />
              <GridButton
                title="Move stitches: Down"
                onClick={() => {
                  props.setDrawType(DrawTypes.Move, -1);
                }}
                imageURL="ui/down.png"
                small={true}
                size={15}
                isSelected={props.moveMode === -1}
                customStyle={{
                  height: "20px",
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div style={{ ...leftUp }}>
        <div
          style={{
            display: "flex",
            marginTop: "50px",
            justifyContent: "flex-start",
            flexDirection: "column",
          }}
        >
          {!warningOverlay && props.isSweaterPart && (
            <div
              style={{
                marginLeft: "5px",
                marginTop: "0px",
              }}
            >
              <p
                style={{
                  fontSize: "12px",
                  marginBottom: "auto",
                }}
              >
                24 stitches per 10cm
              </p>
              <p
                style={{
                  fontSize: "12px",
                  marginBottom: "auto",
                }}
              >
                32 rounds per 10cm
              </p>
              <hr style={{ margin: "5px 0 5px 0" }} />
              <p
                style={{
                  fontSize: "12px",
                  marginBottom: "auto",
                }}
              >
                {Settings.circumfencesCM[Global._sizeIndex]} cm chest
              </p>
              <p
                style={{
                  fontSize: "12px",
                  marginBottom: "auto",
                }}
              >
                {Settings.lengthCM[Global._sizeIndex]} cm length
              </p>
            </div>
          )}
          {warningOverlay && props.isSweaterPart && (
            <div
              style={{
                marginLeft: "5px",
                marginTop: "0px",
              }}
            >
              <p
                style={{
                  fontSize: "20px",
                  marginBottom: "auto",
                }}
              >
                Hover over highlighted areas
              </p>
              <p
                style={{
                  fontSize: "20px",
                  marginTop: "auto",
                }}
              >
                to see cause of concern
              </p>
              <div
                style={{
                  display: "flex",
                  marginTop: "0px",
                  flexDirection: "row",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    marginTop: "10px",
                    color: Settings.knitabilityWarningColorDisplay,
                    marginRight: "5px",
                  }}
                >
                  Yellow:
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    marginTop: "auto",
                  }}
                >
                  Challenging
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  flexDirection: "row",
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    marginTop: "auto",
                    color: Settings.knitabilityErrorColor,
                    marginRight: "5px",
                  }}
                >
                  Red:
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    marginTop: "auto",
                  }}
                >
                  Impossible
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={{ ...rightUp }}>
        {props.useZoom && (
          <div className="buttonContainer" style={{ pointerEvents: "auto" }}>
            <GridButton
              title="Delete"
              onClick={() => {
                props.trashButtonClick();
              }}
              imageURL="ui/delete2.png"
              small={true}
              size={20}
              opacity={props.trashButtonState() ? 1 : 0.25}
            />
            <GridButton
              title="Zoom: Decrease"
              onClick={() => {
                props.zoom(-1);
              }}
              imageURL="ui/minus.png"
              small={true}
              size={20}
            />
            <GridButton
              title="Zoom: Increase"
              onClick={() => {
                props.zoom(1);
              }}
              imageURL="ui/add2.png"
              small={true}
              size={20}
            />
          </div>
        )}
        {!props.isSweaterPart && (
          <>
            <div
              style={{
                display: "flex",
                width: "80px",
                height: "40px",
                backgroundColor: "white",
                border: "2px solid #f0f0f0",
                borderRadius: "3px",
                paddingLeft: "7.5px",
                paddingRight: "7.5px",
                justifyContent: "space-between",
                pointerEvents: "auto",
                alignItems: "center",
              }}
            >
              <NumberInput
                title={"Width"}
                input={props.patternWidth}
                setInput={props.setPatternWidth}
                customStyle={{
                  backgroundColor: "#f0f0f0",
                  fontSize: "12px",
                  border: "none",
                  borderRadius: "2px",
                  width: "20px",
                  height: "20px",
                  textAlign: "center",
                  pointerEvents: "auto",
                  fontWeight: 300,
                }}
                useHandle={false}
                acceptNegative={false}
                setDoIfClickedOutside={props.setDoIfClickedOutside}
              />
              <p style={{ textAlign: "center", fontWeight: 300 }}>x</p>
              <NumberInput
                title={"Height"}
                input={props.patternHeight}
                setInput={(it: number) => {
                  props.setPatternHeight(it);
                  props.setEditPatternHeight(
                    props.drawable.calculateEditPatternHeight(it)
                  );
                }}
                customStyle={{
                  backgroundColor: "#f0f0f0",
                  fontSize: "12px",
                  border: "none",
                  borderRadius: "2px",
                  width: "20px",
                  height: "20px",
                  textAlign: "center",
                  pointerEvents: "auto",
                  fontWeight: 300,
                }}
                useHandle={false}
                acceptNegative={false}
                setDoIfClickedOutside={props.setDoIfClickedOutside}
              />
            </div>
            <div
              className="buttonContainer"
              style={{
                pointerEvents: "auto",
              }}
            >
              <Tooltip title="Save" disableInteractive>
                <button
                  className="gridButton small"
                  onClick={() => {
                    props.SaveButtonClick();
                  }}
                  style={{ margin: "auto" }}
                >
                  <img
                    src={"ui/save.png"}
                    style={{
                      height: "22px",
                      imageRendering: "crisp-edges",
                      opacity: props.SaveButtonState() ? 1 : 0.25,
                    }}
                  ></img>
                </button>
              </Tooltip>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default GridOverlay;
