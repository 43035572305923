import React, { useRef } from "react";

import { useEffect, useState } from "react";
import {
  KnitabilityProblem,
  DrawTypes,
  RepeatMode,
  SweaterPartArea,
  SweaterPartAreaGroup,
} from "../enums";
import {
  colorsScene,
  getSweaterParts,
  makeScene,
  setColorsScene,
  setUpdateCanvasNextFrame,
} from "../knittingpreview/scene";
import { Pattern } from "../Pattern";
import { Settings } from "../static/settings";
import { SweaterPart } from "../SweaterPart";
import Tools from "./Tools";
import Drawable from "../Drawable";
import PatternTools from "./PatternTools";
import Grid from "./Grid";
import { Util } from "../static/util";
import { methodData } from "../httpRequests";
import { RootState } from "../../store/store";
import { useSelector, useDispatch } from "react-redux";
import { selectKey, setKey } from "../../store/keyslice";
import { useAppDispatch } from "../../store/hooks";
import { Raglan } from "../data/raglan";
import { Isydd } from "../data/isydd";
import { Global } from "../static/global";

// Must be outside KnittingEditor, or else it wont be affected by loadPatternGrids()
let patternGrids: { [name: string]: number[][] } = {};

function KnittingEditor(props: any) {
  const [useBucket, setUseBucket] = useState(false);
  const [useEraser, setUseEraser] = useState(false);
  const [brushID, setBrushID] = useState(1);
  const [colorID, setColorID] = useState(1);
  const [colors, setColors] = useState(
    Settings.startingColors.map((it) => Settings.colorsAll[it])
  );
  const [moveMode, setMoveMode] = useState(0);
  const patternNames = [
    "Mangfold",
    "Lokal",
    "Bord 4",
    "Kvinnesak",
    "Tradisjonsmat",
    "Bord 2",
    "Natur",
    //"blomst",
    "New Diagram",
    "Farge",
    "Bord",
    "Bord 3",
    "Korn",
    "Hjertevarm",
    "Norge",

    "marius",
    "marius2",
    "marius3",
    "marius4",
    "marius5",
    "marius6",

    "28",
    "11",
    "10",
    "16",
    "13",
    "4",
    "1",
    "23",
    "27",
    "6",
    "24",
    "2",
    "7",
    "5",
    "24_v2",
    "8",
    "17",
    "12",
    "21",
    "26",
    "30",
    "9",
    "14",
    "29",
    "20",
    "18",
    "3",
    "22",
  ];
  const [patternID, setPatternID] = useState(-1);
  const [repeatOptions, setRepeatOptions] = useState([
    "None",
    "None",
    "None",
    "None",
  ]); // Is updated after selecting props.selectedPart
  const [gapMemoBrush, setGapMemoBrush] = useState(16);
  const [gap, setGap] = useState(gapMemoBrush);
  const [gapMemoPattern, setGapMemoPattern] = useState(0);

  const [patterns, setPatterns] = useState<Pattern[]>([]);

  const repeatModes = [
    RepeatMode.NONE,
    RepeatMode.ONE,
    RepeatMode.BOTH,
    RepeatMode.ALL,
  ];
  const [repeat, setRepeat] = useState(3);
  const [repeatMemo, setRepeatMemo] = useState(3);

  const [mirrorOptions, setMirrorOptions] = useState([
    "None",
    "None",
    "None",
    "None",
  ]); // Is updated after selecting props.selectedPart
  const [mirror, setMirror] = useState(1);
  const [mirrorMemoBrush, setMirrorMemoBrush] = useState(1);
  const [mirrorMemoPattern, setMirrorMemoPattern] = useState(0);

  const [doIfClickedOutside, setDoIfClickedOutside] = useState<any[]>([]);

  const undoButtonClickRef = useRef<() => void>(() => {});
  const redoButtonClickRef = useRef<() => void>(() => {});
  const trashButtonClickRef = useRef<() => void>(() => {});

  const [undoButtonState, setUndoButtonState] = useState(false);
  const [redoButtonState, setRedoButtonState] = useState(false);
  const [trashButtonState, setTrashButtonState] = useState(false);

  const [patternIsUnsaved, setPatternIsUnsaved] = useState(false);

  const [editPatternHeight, setEditPatternHeight] = useState(
    Settings.defaultPatternHeightVH
  );

  const [loadModel, setLoadModel] = useState<boolean>(false);
  const [modelFromPath, setModelFromPath] = useState<any>();
  const [refreshPattern, setRefreshPattern] = useState<boolean>();
  const [drawType, _setDrawType] = useState<DrawTypes>(DrawTypes.Brush);

  const key = useSelector(selectKey);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.startMenuOpen) return;
    init();
  }, [props.startMenuOpen]);

  useEffect(() => {
    setRefreshPattern(false);
  }, [refreshPattern]);

  //update ui, e.x. repeat/mirror should include the new items
  useEffect(() => {
    setEditPatternHeight(
      props.selectedEditPattern?.calculateEditPatternHeight() ??
        Settings.defaultPatternHeightVH
    );
    if (props.selectedSweaterPart === undefined) return;
    if (props.selectedEditPattern === undefined) {
      setPatternIsUnsaved(false);
      updateUI(props.selectedSweaterPart, drawType);
    }
  }, [props.selectedEditPattern]);

  useEffect(() => {
    if (props.selectedSweaterPart === undefined) return;
    updateUI(props.selectedSweaterPart, drawType);
  }, [props.selectedSweaterPart, drawType]);

  useEffect(() => {
    if (key === "") return;
    loadFromKey(key);
  }, [key]);

  useEffect(() => {
    if (props.selectedSweaterPart === undefined) return;
    //NB: Consider getElement check
    setColorsScene(colors);
    for (let sweaterPart of getSweaterParts()) {
      sweaterPart.setDirty();
    }
    setUpdateCanvasNextFrame();
    saveColors();
  }, [colors]);

  function updateUI(drawable: Drawable, drawType: DrawTypes) {
    const sweaterPart = drawable as SweaterPart;
    if (sweaterPart.isCollar()) {
      if (repeat !== 0) {
        setRepeat(1);
      }
      setMirror(0);
      setRepeatOptions(["None", sweaterPart.name]);
      setMirrorOptions(["None"]);
    } else {
      const thirdRepeatOption = sweaterPart.isArm() ? "Arms" : "Torso";
      setRepeat(repeatMemo);
      setRepeatOptions([
        "None",
        sweaterPart.name,
        thirdRepeatOption,
        "Arms & Torso",
      ]);
    }
    if (drawType == DrawTypes.Pattern) {
      setGap(gapMemoPattern);
      setMirror(mirrorMemoPattern);
    } else {
      setGap(gapMemoBrush);
      setMirror(mirrorMemoBrush);
    }
  }

  function loadPatternGrids() {
    fetch("data/patterns5.json")
      .then((response) => response.json())
      .then((json) => {
        patternGrids = json;

        saveAndUsePatternGrids();
      });
  }

  function saveAndUsePatternGrids() {
    const newPatterns: Pattern[] = [];
    for (let n = 0; n < patternNames.length; n++) {
      let patternName = patternNames[n];
      const grid = patternGrids[patternName];
      for (let y in grid) {
        for (let x in grid[y]) {
          if (grid[y][x] !== -1) {
            grid[y][x] = colorID;
          }
        }
      }
      newPatterns.push(new Pattern(grid));
    }
    const patternsJSON = JSON.stringify(
      newPatterns.map((pattern: Pattern) => pattern.grid)
    );
    localStorage.setItem(Global.getPatternStorageName(), patternsJSON);

    setPatterns(newPatterns);
  }

  function loadPattern(patternID: number) {
    const grid = patterns[patternID].copyGrid();
    if (Util.patternIsMonocolored(patterns[patternID])) {
      for (let y in grid) {
        for (let x in grid[y]) {
          if (grid[y][x] !== -1) {
            grid[y][x] = colorID;
          }
        }
      }
    }
    return new Pattern(grid);
  }

  function loadPatternsFromLocalStorage() {
    let patternsJSON = localStorage.getItem(Global.getPatternStorageName());
    if (!patternsJSON) {
      loadPatternGrids();
    } else {
      const _patterns = JSON.parse(patternsJSON);
      setPatterns(_patterns.map((grid: any) => new Pattern(grid)));
    }
  }

  function loadColorsFromLocalStorage() {
    const colorsJSON = localStorage.getItem(Global.getColorsStorageName());
    let _colors = colors;
    if (colorsJSON) {
      _colors = JSON.parse(colorsJSON);
      setColors(_colors);
    }
    setColorsScene(_colors);
    return _colors;
  }

  function makeModelScene(colors: any) {
    // 2128, 1986, 3826, 4025

    let element = document.getElementById("canvas")!!;

    let parts: { [key: string]: Raglan | Isydd } = {
      raglan: new Raglan(),
      stitched_sleeves: new Isydd(),
    };

    makeScene(
      element,
      parts[Global._shirtType].parts(),
      colors,
      props.setSelectedSweaterPart
    );
  }

  function loadFromKey(key: string) {
    const url = "https://woolit.no/api/3d/" + key;
    methodData(url, {}, "GET", async (it) => {
      const body = await it.json();
      let colors = body["colors"];
      if (Object.keys(colors).length == 0) {
        alert(
          "Could not retrieve saved `colors`from this sweater. Most likely reason is because it were made in a early version of this application. Therefor, the colors have been reset."
        );
        colors = Settings.startingColors.map((it) => Settings.colorsAll[it]);
      }
      Global.setSizeIndex(body["size"] ?? 2);
      Global.setShirtTypeIndex(body["knittingMethod"] ?? 0);
      setColors(colors);
      saveColors(colors);
      setModelFromPath(body["design"]);
      makeModelScene(colors);
      setLoadModel(true);
      props.setSelectedSweaterPart(undefined);
    });
  }

  function init() {
    loadPatternsFromLocalStorage();
    const pathName = window.location.pathname.substring(1);
    if (Util.isKey(pathName)) {
      dispatch(setKey(pathName));
    } else {
      const colors = loadColorsFromLocalStorage();
      saveColors(colors);
      makeModelScene(colors);
      setLoadModel(true);
    }
  }

  function getDrawType() {
    return drawType;
  }

  function setDrawType(drawType: DrawTypes, index?: number) {
    let brushID = -1;
    let patternID = -1;
    let useBucket = false;
    let useEraser = false;
    let moveMode = 0;
    switch (drawType) {
      case DrawTypes.Brush: {
        brushID = index!;
        break;
      }
      case DrawTypes.Pattern: {
        patternID = index!;
        break;
      }
      case DrawTypes.Bucket: {
        useBucket = true;
        break;
      }
      case DrawTypes.Eraser: {
        brushID = index!;
        useEraser = true;
        break;
      }
      case DrawTypes.Move: {
        moveMode = index!;
        break;
      }
    }
    setBrushID(brushID);
    setPatternID(patternID);
    setUseBucket(useBucket);
    setUseEraser(useEraser);
    setMoveMode(moveMode);
    _setDrawType(drawType);
  }

  function patternGetMonocoloredColorIfExists(patternID: number) {
    const pattern = patterns[patternID];
    const colors = new Set(pattern.grid.flat(1));
    colors.delete(-1);
    if (colors.size > 1 || colors.size === 0) return undefined;
    const firstElement = colors.values().next().value;
    return firstElement;
  }

  /*function changeMaskSize(size: string) {
        const oldMasksPer10Cm = Settings.masksPer10Cm
        switch (size) {
            case "S": Settings.masksPer10Cm = 28; break;
            case "M": Settings.masksPer10Cm = 18; break;
            case "L": Settings.masksPer10Cm = 12; break;
        }
        if (oldMasksPer10Cm === Settings.masksPer10Cm) return;
        for (let sweaterPart of getSweaterParts()) {
            sweaterPart.setDirty()
        }
        Settings.updateCanvasDimensions()
        resetCanvas()
        unCacheDraw()
        loadGrid(props.selectedSweaterPart, setGrid) //Re-select sweaterPart
    }*/

  function getDoIfClickedOutside(e: any) {
    if (doIfClickedOutside.length === 0) return undefined;
    const clickedElement = e.target;
    if (!clickedElement) return undefined;
    const [action, parentCall] = doIfClickedOutside;
    let parentElements = parentCall();
    if (!parentElements) return undefined;
    if (!Array.isArray(parentElements)) {
      parentElements = [parentElements];
    }
    for (let parentElement of parentElements) {
      if (parentElement?.contains(clickedElement)) return undefined;
    }
    return action;
  }

  function getStateSweaterParts() {
    return getSweaterParts().map((part: SweaterPart) => part.copyGrid());
  }

  function SavePattern() {
    let newPatterns: Pattern[] = [];
    let switchToPattern = true;
    let switchToPatternID = patterns.length;
    if (props.selectedEditPattern.id === -1) {
      if (Util.patternIsEmpty(props.selectedEditPattern.grid)) {
        setDrawType(DrawTypes.Brush, 1);
        return;
      }
      const pattern = new Pattern(props.selectedEditPattern.copyGridBySize());
      newPatterns = [...patterns, pattern];
    } else {
      newPatterns = [...patterns];
      const id = props.selectedEditPattern.id;
      if (Util.patternIsEmpty(props.selectedEditPattern.grid)) {
        newPatterns.splice(id, 1);
        switchToPattern = false;
      } else {
        newPatterns[id] = new Pattern(
          props.selectedEditPattern.copyGridBySize()
        );
        switchToPatternID = id;
      }
    }
    setPatterns(newPatterns);
    if (switchToPattern) {
      setDrawType(DrawTypes.Pattern, switchToPatternID);
    } else {
      setDrawType(DrawTypes.Brush, 1);
    }

    const patternsJSON = JSON.stringify(
      newPatterns.map((pattern: Pattern) => pattern.grid)
    );
    localStorage.setItem(Global.getPatternStorageName(), patternsJSON);
  }

  function saveColors(_colors?: any) {
    if (_colors == undefined) {
      _colors = colors;
    }
    const colorsJSON = JSON.stringify(_colors);
    localStorage.setItem(Global.getColorsStorageName(), colorsJSON);
  }

  return (
    <div
      onMouseDown={(e: any) => {
        getDoIfClickedOutside(e)?.();
      }}
      onContextMenu={(e) => {
        //Right click
        e.preventDefault();
      }}
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        minWidth: "100%",
        backgroundColor: "#f9f5f2",
        maxWidth: "100%",
      }}
    >
      <Tools
        gap={gap}
        setGap={setGap}
        setDrawType={setDrawType}
        brushID={brushID}
        useBucket={useBucket}
        patternID={patternID}
        repeat={repeat}
        repeatOptions={repeatOptions}
        setRepeat={setRepeat}
        setRepeatMemo={setRepeatMemo}
        colorID={colorID}
        setColorID={setColorID}
        setDoIfClickedOutside={setDoIfClickedOutside}
        selectedSweaterPart={props.selectedSweaterPart}
        selectedEditPattern={props.selectedEditPattern}
        setSelectedEditPattern={props.setSelectedEditPattern}
        patterns={patterns}
        setPatterns={setPatterns}
        patternGetMonocoloredColorIfExists={patternGetMonocoloredColorIfExists}
        mirror={mirror}
        mirrorOptions={mirrorOptions}
        setMirror={setMirror}
        setMirrorMemoBrush={setMirrorMemoBrush}
        setMirrorMemoPattern={setMirrorMemoPattern}
        getDrawType={getDrawType}
        colors={colors}
        setColors={setColors}
        trashButtonClickRef={trashButtonClickRef}
        trashButtonState={trashButtonState}
      />
      <Grid
        isSweaterPart={true}
        drawable={props.selectedSweaterPart}
        useZoom={true}
        selectedEditPatternDone={props.selectedEditPattern == undefined}
        SavePattern={undefined}
        sendUndoRedoTrashRef={false}
        undoButtonClickRef={undoButtonClickRef}
        redoButtonClickRef={redoButtonClickRef}
        trashButtonClickRef={trashButtonClickRef}
        setUndoButtonState={setUndoButtonState}
        setRedoButtonState={setRedoButtonState}
        setTrashButtonState={setTrashButtonState}
        setEditPatternHeight={undefined}
        setSweaterHasLoaded={props.setSweaterHasLoaded}
        modelFromPath={modelFromPath}
        loadModel={loadModel}
        loadPattern={loadPattern}
        colors={colors}
        setDoIfClickedOutside={setDoIfClickedOutside}
        useEraser={useEraser}
        repeat={repeat}
        setRepeat={setRepeat}
        gap={gap}
        mirror={mirror}
        setMirrorOptions={setMirrorOptions}
        mirrorOptions={mirrorOptions}
        setMirror={setMirror}
        setMirrorMemoBrush={setMirrorMemoBrush}
        setMirrorMemoPattern={setMirrorMemoPattern}
        setRepeatOptions={setRepeatOptions}
        repeatOptions={repeatOptions}
        setDrawType={setDrawType}
        getDrawType={getDrawType}
        brushID={brushID}
        patternID={patternID}
        colorID={colorID}
        moveMode={moveMode}
        repeatModes={repeatModes}
        patternGrids={patternGrids}
        patterns={patterns}
        setColors={setColors}
      />
      <PatternTools
        setDrawType={setDrawType}
        selectedEditPattern={props.selectedEditPattern}
        setSelectedEditPattern={props.setSelectedEditPattern}
        patterns={patterns}
        patternGetMonocoloredColorIfExists={patternGetMonocoloredColorIfExists}
        setColorID={setColorID}
        setRepeat={setRepeat}
        setRepeatMemo={setRepeatMemo}
        repeat={repeat}
        repeatOptions={repeatOptions}
        gap={gap}
        setGap={setGap}
        patternID={patternID}
        setDoIfClickedOutside={setDoIfClickedOutside}
        colors={colors}
        patternIsUnsaved={patternIsUnsaved}
        setRefreshPattern={setRefreshPattern}
      />
      {props.selectedEditPattern && !refreshPattern && (
        <Grid
          isSweaterPart={false}
          drawable={props.selectedEditPattern}
          useZoom={false}
          selectedEditPatternDone={false}
          SavePattern={SavePattern}
          sendUndoRedoTrashRef={true}
          undoButtonClickRef={undefined}
          redoButtonClickRef={undefined}
          trashButtonClickRef={undefined}
          setUndoButtonState={undefined}
          setRedoButtonState={undefined}
          setTrashButtonState={undefined}
          setSelectedEditPattern={props.setSelectedEditPattern}
          selectedEditPattern={props.selectedEditPattern}
          editPatternHeight={editPatternHeight}
          setEditPatternHeight={setEditPatternHeight}
          setSweaterHasLoaded={undefined}
          modelFromPath={undefined}
          loadModel={loadModel}
          loadPattern={loadPattern}
          colors={colors}
          setDoIfClickedOutside={setDoIfClickedOutside}
          useEraser={useEraser}
          repeat={repeat}
          setRepeat={setRepeat}
          gap={gap}
          mirror={mirror}
          setMirrorOptions={setMirrorOptions}
          mirrorOptions={mirrorOptions}
          setMirror={setMirror}
          setRepeatOptions={setRepeatOptions}
          repeatOptions={repeatOptions}
          setDrawType={setDrawType}
          getDrawType={getDrawType}
          brushID={brushID}
          patternID={patternID}
          colorID={colorID}
          repeatModes={repeatModes}
          patternGrids={patternGrids}
          patterns={patterns}
          setPatternIsUnsaved={setPatternIsUnsaved}
          setColors={setColors}
        />
      )}
    </div>
  );
}

export default KnittingEditor;
